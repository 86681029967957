<template>
  <div class="claim-requests-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="9" sm="12">
            <h1>{{$t('TOKEN_PAYOUT_PAGE_TITLE')}}</h1>
          </CCol>
          <CCol md="3" sm="12">
            <CButton
              class="btn btn-upload-csv"
              @click="showUploadCsvModal"
            >{{$t("TOKEN_PAYOUT_PAGE_BUTTON_UPLOAD_CSV")}}</CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchTokenPayoutHeader
          :onSearch="onSearch"
          :downloadCSV="downloadCSV"
          :isAllowedDownloadCSV="isAllowedDownloadCSV"
        />
        <div class="checkbox-section mb-1">
          <label v-show="checkboxMode === 1" class="checkbox-label selected-all-checkbox">
            <input v-indeterminate="null" type="checkbox" @change="onChangeSelectedAll($event)" />
          </label>

          <label v-show="checkboxMode === 0" class="checkbox-label selected-all-checkbox">
            <input
              type="checkbox"
              :checked="true"
              v-model="isSelectedAll"
              @change="onChangeSelectedAll($event)"
            />
          </label>

          <button
            class="btn btn-update-status"
            v-if="isAllowedApprove"
            @click="showPayoutTransferedModal()"
          >{{$t('TOKEN_PAYOUT_PAGE_BUTTON_UPDATE_STATUS')}}</button>
        </div>
        <TokenPayoutListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isSelectedAll="isSelectedAll"
          :isAllowedViewDetails="isAllowedViewDetails"
          :isAllowedApprove="isAllowedApprove"
          :isAllowedReject="isAllowedReject"
          @selectItem="selectItem"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
        <CModal
          class="staking-confirmation-modal payout-transferred-confirmation-modal"
          :show.sync="isShownPayoutTransferedModal"
          :centered="true"
        >
          <template #header>
            <CButtonClose class="close-modal" @click="isShownPayoutTransferedModal = false" />
          </template>
          <div>
            <div class="pb-4 tit-dele">
              <h4>{{$t('TOKEN_PAYOUT_PAGE_LABEL_DESCRIPTION')}}</h4>
            </div>
            <div class="text-modal">
              <CCard class="selected-claim-request-list-table-card">
                <CDataTable :items="selectedItems" :fields="fields" fixed hover striped bordered>
                  <template #email="{item}">
                    <td>
                      <StakingMemberDetailsLink :text="item.member.email" />
                    </td>
                  </template>

                  <template #amount="{item}">
                    <td>
                      <StakingCurrency :value="item.amount" :currencySymbol="item.currency_symbol" />
                    </td>
                  </template>
                </CDataTable>
              </CCard>
              <div class="notification">
                <span v-if="errorMessage">{{$t('TOKEN_PAYOUT_PAGE_LABEL_NOTIFY_ERROR')}}</span>
              </div>
              <div class="grand-total">
                <div class="row">
                  <div class="col-md-7 text-right">
                    <span>{{$t('TOKEN_PAYOUT_PAGE_LABEL_TOTAL')}}</span>
                  </div>
                  <div class="col-md-5 text-right">
                    <StakingCurrency :value="totalAmount" :currencySymbol="currencySymbol" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7 text-right">
                    <span>{{$t('TOKEN_PAYOUT_PAGE_LABEL_TIMESTAMP')}}</span>
                  </div>
                  <div class="col-md-5 text-right">
                    <span>{{ timestamp | moment(dateFormat) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <CButton
              class="btn-cancel"
              @click="isShownPayoutTransferedModal = false"
              color="dark"
              variant="outline"
            >{{$t('TOKEN_PAYOUT_PAGE_BUTTON_CANCEL')}}</CButton>

            <CButton
              @click="updatePayoutTransferred"
              class="btn-ok"
            >{{$t('TOKEN_PAYOUT_PAGE_BUTTON_PAYOUT_TRANSFER')}}</CButton>
          </template>
        </CModal>
      </CCardBody>
    </CCard>
    <UploadCSVModal
      :isOpenModal="isOpenModal"
      :postTo="postTo"
      :keyTxIdName="keyTxIdName"
      @close-modal="handleUploadCsvModal"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { mapState, mapActions, mapGetters } from 'vuex';
import TokenPayoutListTable from '@/components/TokenPayout/TokenPayoutListTable';
import SearchTokenPayoutHeader from '@/components/TokenPayout/SearchTokenPayoutHeader';
import StakingPagination from '@/components/StakingPagination';
import StakingCurrency from '@/components/StakingCurrency';
import StakingMemberDetailsLink from '@/components/StakingMemberDetailsLink';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { ClaimRequestStatus } from '@/enums';
import { Constants, env } from '@/constants';
import UploadCSVModal from '@/components/UploadCSVModal/index';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'TokenPayoutList',
  components: {
    TokenPayoutListTable,
    SearchTokenPayoutHeader,
    StakingPagination,
    StakingCurrency,
    StakingMemberDetailsLink,
    UploadCSVModal,
  },
  data() {
    return {
      isOpenModal: false,
      postTo: endpoints.uploadTxIdTokenPayout,
      keyTxIdName: 'tokenPayoutTxid',
      isIndeterminate: false,
      isSelectedAll: false,
      checkboxMode: 0,
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDownloadCSV: false,
      isShownPayoutTransferedModal: false,
      isAllowedViewDetails: false,
      isAllowedApprove: false,
      isAllowedReject: false,
      selectedItem: null,
      isProcessing: false,
      dateFormat: Constants.dateFormat,
      selectedItems: [],
      totalAmount: 0,
      currencySymbol: '',
      timestamp: new Date(),
      fields: [
        {
          key: 'email',
          label: this.$t('TOKEN_PAYOUT_PAGE_TABLE_COL_EMAIL'),
        },
        {
          key: 'amount',
          label: this.$t('TOKEN_PAYOUT_PAGE_TABLE_COL_AMOUNT'),
        },
      ],
      errorMessage: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'AFFILIATE_TOKEN_PAYOUT_EXPORT_CSV') {
            this.isAllowedDownloadCSV = true;
          }
          if (item.name === 'AFFILIATE_TOKEN_PAYOUT_VIEW_DETAIL') {
            this.isAllowedViewDetails = true;
          }
          if (item.name === 'AFFILIATE_TOKEN_PAYOUT_APPROVE') {
            this.isAllowedApprove = true;
          }
        });
      }
    },
    async getTokenPayouts(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          from_date: filterData.fromDate,
          to_date: dateTimeHelper.adjustToDate(filterData.toDate),
          email: filterData.email,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
          memo: filterData.memo,
          status: filterData.status,
          payout_from_date: filterData.payoutFromDate,
          payout_to_date: dateTimeHelper.adjustToDate(filterData.payoutToDate),
          crypto_platform: filterData.currency,
        };
        const result = await this.$http.get(endpoints.getTokenPayout, { params });
        this.items = result.data.items || [];
        this.items.forEach(item => {
          item.status = ClaimRequestStatus[item.status];
          item.id = stringHelper.padDigits(item.id, 4);
          item.amount = Number(item.amount);
        });

        this.total = result.data.total;

        this.isSelectedAll = false;
        this.isIndeterminate = false;
        this.errorMessage = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_LIST_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getTokenPayouts();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getTokenPayouts();
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData('filter-token-payout');
      this.activePage = 1;

      this.getTokenPayouts(data);
    },
    async downloadCSV(data) {
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          from_date: filterData.fromDate,
          to_date: dateTimeHelper.adjustToDate(filterData.toDate),
          email: filterData.email,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
          memo: filterData.memo,
          status: filterData.status,
          payout_from_date: filterData.payoutFromDate,
          payout_to_date: dateTimeHelper.adjustToDate(filterData.payoutToDate),
          time_offset: new Date().getTimezoneOffset(),
          crypto_platform: filterData.currency,
        };
        const result = await this.$http.get(endpoints.downloadCsvTokenPayout, { params });

        const data = result;
        const fileUrl = 'token-payout.dat';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_LIST_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onChangeSelectedAll() {
      if (this.checkboxMode === 1) {
        this.isSelectedAll = false;
      }

      this.checkboxMode = 0;
      this.isIndeterminate = false;
      this.items.forEach(item => (item.isSelected = this.isSelectedAll));
      this.items = this.items.splice(0);
      this.errorMessage = false;
    },
    selectItem(item) {
      const len = this.items.length;
      const numOfSelectedItems = this.items.filter(x => x.isSelected).length;

      if (numOfSelectedItems === 0) {
        this.isIndeterminate = false;
        this.isSelectedAll = false;
        this.checkboxMode = 0;
      } else if (numOfSelectedItems === len) {
        this.isIndeterminate = false;
        this.isSelectedAll = true;
        this.checkboxMode = 0;
      } else {
        this.isSelectedAll = false;
        this.isIndeterminate = true;
        this.checkboxMode = 1;
        this.errorMessage = false;
      }
    },
    showPayoutTransferedModal() {
      const selectedItems = (this.selectedItems = this.items.filter(x => x.isSelected));
      const numOfSelectedItems = selectedItems.length;
      if (!numOfSelectedItems) {
        return;
      }

      this.timestamp = new Date();
      this.totalAmount = _.sumBy(selectedItems, 'amount');
      this.currencySymbol = selectedItems[0].currency_symbol;
      this.isShownPayoutTransferedModal = true;
      this.errorMessage = false;
    },
    async updatePayoutTransferred() {
      const hasApprovedItem = _.some(
        this.items.filter(x => x.isSelected),
        item => item.status !== 'Payout pending',
      );
      if (hasApprovedItem) {
        this.errorMessage = true;
        return;
      }

      this.errorMessage = false;
      this.updateTokenPayout(ClaimRequestStatus.Approved);
    },
    async updateTokenPayout(status) {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;
      try {
        const data = {
          token_payout_ids: this.items.filter(x => x.isSelected).map(x => Number(x.id)),
        };
        const result = await this.$http.put(endpoints.approveTokenPayout, data);
        this.isProcessing = false;
        this.isShownPayoutTransferedModal = false;
        this.getTokenPayouts();
      } catch (err) {
        this.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_LIST_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    showUploadCsvModal() {
      this.isOpenModal = true;
    },
    handleUploadCsvModal(isRefeshData) {
      this.isOpenModal = false;
      if (isRefeshData) {
        this.getTokenPayouts();
      }
    },
  },
};
</script>
<style lang="scss">
.claim-requests-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .checkbox-section {
    .selected-all-checkbox {
      width: 40px;
      text-align: center;
    }

    .btn-update-status {
      width: 111px;
      border: 1px solid #1a2958;
      border-radius: 15px;
      background-color: #ffffff;
      color: #4d5666;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
  .selected-claim-request-list-table-card {
    margin-bottom: 0;
  }
  .card {
    .notification {
      color: red;
      margin: 5px 0 20px 0;
      font-size: 13px;
    }
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
      .btn-upload-csv {
        border: none;
        border-radius: 32px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border: 1px solid #1a2958;
        border-radius: 17.5px;
        background-color: #1a2958;
        width: 100%;
        color: #5da30b;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        border: 1px solid #5da30b;
        border-radius: 16px;
        background-color: #ffffff;
      }
    }
  }
}

.staking-confirmation-modal.payout-transferred-confirmation-modal {
  .selected-claim-request-list-table-card {
  }

  .grand-total {
    padding: 0px 15px;
    .staking-currency {
      color: #4d5666;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-align: right;
    }
  }

  .modal-footer {
    .btn-ok {
      width: 200px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-align: center;
      border-radius: 4px;
      background-color: #5da30b;
    }
  }
}
</style>
